#__next {
  height: 100%;
}

/* this is 苦肉の策 */
.tw-text-h1,
.tw-text-h2,
.tw-text-h3,
.tw-text-h4,
.tw-text-h5,
.tw-text-base1Title,
.tw-text-base2Title,
.tw-text-base3Title,
.tw-text-button {
  font-feature-settings: 'halt';
}
